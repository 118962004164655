import App from "../App";
import PrivacyPolic from '../view/privacyPolic';
import DeleteUser from '../view/deleteUser';
import DeleteAccountInfo from '../view/deleteUser/info';
import DeleteStatUser from '../view/deleteStatUser';
import DeleteStatAccountInfo from '../view/deleteStatUser/info';
import UserAgreement from '../view/userAgreement';
import StatPrivacyPolic from '../view/statPrivacyPolic';
import StatUserAgreement from '../view/statUserAgreement';
import OntaxeUserAgreement from '../view/ontaxeUserAgreement';
import OntaxePrivacyPolic from '../view/ontaxePrivacyPolic';
import ToposfyWebSite from '../view/toposfyWebSite';

import {
  createHashRouter
} from "react-router-dom";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <ToposfyWebSite />,
      },
    ],
  },
  {
    path: "privacyPolic",
    element: <PrivacyPolic />,
  },
  {
    path: "deleteAccountIndex",
    element: <DeleteUser />,
  },
  {
    path: "deleteAccountInfo",
    element: <DeleteAccountInfo />,
  },
  {
    path: "deleteStatAccountIndex",
    element: <DeleteStatUser />,
  },
  {
    path: "deleteStaAccountInfo",
    element: <DeleteStatAccountInfo />,
  },
  {
    path: "userAgreement",
    element: <UserAgreement />,
  },
  {
    path: "statPrivacyPolic",
    element: <StatPrivacyPolic />,
  },
  {
    path: "statUserAgreement",
    element: <StatUserAgreement />,
  },
  {
    path: "ontaxePrivacyPolic",
    element: <OntaxePrivacyPolic />,
  },
  {
    path: "ontaxeUserAgreement",
    element: <OntaxeUserAgreement />,
  },
]);
export default router;
