import { styled } from "@mui/material/styles";
import Footer from "../../layout/footer/index";
import bg from "../../assets/partners/bg.jpg";
import MainLogo from '../../assets/logo/main-logo.png';
import Partners from '../../assets/partners/1.png';
import AppleImg1 from '../../assets/appleImg/1.png';
import AppleImg2 from '../../assets/appleImg/2.png';
import AppleImg3 from '../../assets/appleImg/3.png';
import AppleImg4 from '../../assets/appleImg/4.png';
import AppleImg5 from '../../assets/appleImg/5.png';
import AppleImg6 from '../../assets/appleImg/6.png';
import { Grid, Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeLang } from "../../store/langSlice";
import { changeLangIndex } from "../../store/langIndexSlice";
import { useTranslation } from "react-i18next";
import * as React from "react";

function Top() {
  const ContainerTop = styled("div")(() => ({
    height: 119,
    backgroundColor: "rgba(227, 227, 227, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center'
  }));
  return (
    <ContainerTop>
      <img src={MainLogo} alt="toposfy Device" style={{width: 178, height: 60}} />
    </ContainerTop>
  );
}

const PTitle = styled("div")(() => ({
  fontSize: 30,
  fontWeight: 700,
  color: "rgba(49, 55, 61, 1)",
  paddingTop: "32px",
  paddingBottom: "24px",
}));

const PInfo = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  color: "rgba(49, 55, 61, 1)",
  paddingBottom: "24px",
  [theme.breakpoints.up("xl")]: {
    width: 800,
  },
  [theme.breakpoints.down("xl")]: {
    width: 800,
  },
  [theme.breakpoints.down("lg")]: {
    width: 800,
  },
  [theme.breakpoints.down("md")]: {
    width: "auto",
    padding: "0 8px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    padding: "0 8px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "auto",
    padding: "0 8px",
  },
}));

function Overview() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(244, 244, 245, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "auto"
  }));
  return (
    <ContainerTop>
      <img src={bg} alt="toposfy Device" style={{maxWidth: '100%'}} />
    </ContainerTop>
  );
}

function TopText() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(244, 244, 245, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }));
  const content = 'Chengdu Huitong Times Software Co., Ltd. is located in Chengdu, China and was established on May 13, 2021. The team has many years of experience in software development and market operations, focusing on developing software products suitable for overseas markets. At present, the company has platform products with independent intellectual property rights, including cash register software system, smart passenger transportation, and government fee collection platform, all based on the SaaS model. Provide business management for small and medium-sized customers around the world, the product supports Android, iOS, Windows platforms, and supports multiple languages ​​and multiple countries.'
  return (
    <ContainerTop id="featureTop">
      <PTitle>About Us</PTitle>
      <PInfo>{content}</PInfo>
      <img src={Partners} alt="toposfy Device" style={{maxWidth: 606}} />
    </ContainerTop>
  );
}

function TextTow() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }));
  const content = 'Toposfy Dashboard is an easy-to-use cash register system designed for small to medium-sized retail stores and restaurants. It provides a complete solution to help you manage sales, inventory and customer information, thereby improving work efficiency, reducing errors, and optimizing your business processes.'
  return (
    <ContainerTop>
      <PTitle>Toposfy Dashboard</PTitle>
      <PInfo>{content}</PInfo>
      <Box sx={{marginBottom: 20, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
        <img src={AppleImg1} alt="toposfy Device" style={{maxWidth: 270}} />
        <img src={AppleImg2} alt="toposfy Device" style={{maxWidth: 270}} />
        <img src={AppleImg3} alt="toposfy Device" style={{maxWidth: 270}} />
      </Box>
    </ContainerTop>
  );
}

function TextTow2() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(244, 244, 245, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }));
  const content = 'Intuitive and easy-to-use cash register app for managing retail, restaurants and cafes.'
  return (
    <ContainerTop>
      <PTitle>TOPOSY POS</PTitle>
      <PInfo>{content}</PInfo>
      <Box sx={{marginBottom: 20, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
        <img src={AppleImg4} alt="toposfy Device" style={{maxWidth: 270}} />
        <img src={AppleImg5} alt="toposfy Device" style={{maxWidth: 270}} />
        <img src={AppleImg6} alt="toposfy Device" style={{maxWidth: 270}} />
      </Box>
    </ContainerTop>
  );
}

function TextThree() {
  const ContainerTop = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  }));
  return (
    <ContainerTop>
      <PTitle>Contact Us</PTitle>
      <PInfo>For business cooperation and any questions, please contact us</PInfo>
    </ContainerTop>
  );
}

function RowItem() {
  const MainTitle = styled("div")(() => ({
    fontSize: 20,
    fontWeight: 700,
    color: "rgba(49, 55, 61, 1)",
    paddingTop: "30px",
    paddingBottom: "16px",
  }));
  const data = [
    {
      city: "CHENGDU · China",
      addr: "Room 402, Unit 2, Building 2, Shangding International, No. 27 Renmin South Road, Wuhou District, Chengdu",
      phone: "008618683625329",
      info: "Customer service hotline from Monday to Sunday 0:00-24:00",
      email: "332965272@qq.com",
    },
  ];
  return data.map((v) => {
    return (
      <>
        <Grid container sx={{ display: { xs: "none", md: "flex" }, marginBottom: 20 }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8} sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <div id="item0">
                <MainTitle>{v.city}</MainTitle>
                <Grid
                  container
                  sx={{
                    pb: 3,
                    borderBottom: "1px solid rgba(204, 204, 204, 1)",
                  }}
                  spacing={11.875}
                >
                  <Grid item xs={4}>
                    {v.addr}
                  </Grid>
                  <Grid item xs={4}>
                    <div>{v.phone}</div>
                    <div>{v.info}</div>
                  </Grid>
                  <Grid item xs={4} sx={{textTransform: 'lowercase !important'}}>
                    {v.email}
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection: 'column', padding: 3 }}>
          <MainTitle sx={{padding: 0, paddingBottom: 2}}>{v.city}</MainTitle>
          <Box>{v.addr}</Box>
          <Box>
            <div>{v.phone}</div>
            <div>{v.info}</div>
          </Box>
          <Box>{v.email}</Box>
        </Box>
      </>
    );
  });
}

function Web() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage('en_GB');
    localStorage.setItem("lang", 'en_GB');
    localStorage.setItem("langIndex", 0);
    dispatch(changeLang('en_GB'));
    dispatch(changeLangIndex(0));
  }, [i18n, dispatch]);
  return (
    <>
      <Top />
      <Overview />
      <TopText />
      <TextTow />
      <TextTow2 />
      <TextThree />
      <RowItem />
      <Footer />
    </>
  );
}
export default Web;