import * as React from "react";
import { styled } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeSkin } from "./store/skinSlice";
import { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.black,
  height: "100vh",
  width: "100%",
  minHeight: 500,
  position: "relative",
}));

const layoutTheme = createTheme({
  breakpoints: {
    values: {
      xs: 250,
      xm: 520,
      sm: 760,
      md: 960,
      mg: 961,
      lg: 1280,
      lgUp: 1281,
      gx: 1360,
      xl: 1440,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  let loc = useLocation();
  useEffect(() => {
    let data = { light: true, barBg: "#eee", menuBg: "#fff", color: "#000" };
    if (loc.pathname.includes("/scene") || loc.pathname.includes("/partners")) {
      data = {
        light: false,
        barBg: "rgba(244, 244, 245, 1)",
        menuBg: "rgb(227 227 227)",
        color: "#000",
      };
    }
    dispatch(changeSkin(data));
  });
  return (
    <ThemeProvider theme={layoutTheme}>
      <ProductHeroLayoutRoot>
        <Outlet />
      </ProductHeroLayoutRoot>
    </ThemeProvider>
  );
}

export default App;
